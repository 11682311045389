import learnMoreImg from 'assets/learn-more.svg';
import Image from 'next/image';
import Link from 'next/link';
import styles from './LearnMore.module.scss';

const LearnMore = () => {
  return (
    <div className={styles.container}>
      <Link href="https://ldnvoice.factorylabs.org/blog" alt="learn more" target="_blank">
        <Image src={learnMoreImg} alt="Learn more image" priority />
      </Link>
    </div>
  );
};

export default LearnMore;
