import close from 'assets/close.svg';
import classNames from 'classnames';
import Image from 'next/image';
import styles from './Modal.module.scss';

const Modal = ({
  children,
  isOpen,
  onCloseClick,
  hideClose = false,
  height,
  className,
  addPadding = true
}) => {
  if (!isOpen) return;
  return (
    <div className={classNames(styles.container, className)} style={{ height: `${height}px` }}>
      <div className={styles.content} style={{ height: `${height}px` }}>
        <div className={styles.leftMargin} />
        {!hideClose && (
          <Image src={close} alt="close icon" onClick={onCloseClick} className={styles.closeIcon} />
        )}
        <div className={classNames(styles.children, addPadding && styles.childrenPadding)}>
          {children}
        </div>
      </div>
    </div>
  );
};

export default Modal;
