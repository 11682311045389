import classNames from 'classnames';
import Button from 'components/Activities/Button';
import LearnMore from 'components/Activities/LearnMore';
import Modal from 'components/Activities/Modal';
import SignInButton from 'components/Siwe/Siwe';
import { useGlobalState } from 'globalStateStore';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import { useRouter } from 'next/router';
import { useAccount } from 'wagmi';
import styles from './Home.module.scss';

const Home = () => {
  const [address] = useGlobalState('address');
  const { isConnected } = useAccount();
  const pathname = usePathname();

  const router = useRouter();
  const { code } = router.query;

  const isClaimPage = pathname === '/activities/claimVoice';

  return (
    <>
      {!address && !isConnected && (
        <Modal isOpen={true} height={200} hideClose={true}>
          <div className={styles.modal}>
            <div className={styles.text}>
              {isClaimPage
                ? 'You have been selected to join “London Voice”! '
                : 'Already have an account?'}
            </div>
            <div className={styles.signInButton}>
              <SignInButton
                walletElement={
                  <Button className={styles.button} type="button">
                    {code ? 'Sign up' : 'Login'} / connect
                  </Button>
                }></SignInButton>
            </div>
            <Link href="https://ldnvoice.factorylabs.org/post/the-voting-system" target="_blank">
              Learn more about how it works
            </Link>
          </div>
        </Modal>
      )}
      <div
        className={classNames(
          styles.container,
          !address && !isConnected && styles.containerNotConnected
        )}>
        <div className={styles.paragraph}>
          A democratically built AI, trained by the real opinions of real London citizens, designed
          to serve as a collective voice for the community.
        </div>
        <div className={styles.blackSection}>
          <h1>
            What does <br /> London Voice do?
          </h1>
          <div className={styles.doings}>
            <span>Understands and represents the diverse voices of London</span>
            <span>Makes proposals for improvements based on solutions people have suggested</span>
            <span>Allows people to own, control and monetise their data</span>
            <span>Provides deep insights into issues that people really care about</span>
          </div>
        </div>
        <div className={styles.paragraph}>
          <h2>When was the last time you were asked your opinion on the issues YOU care about?</h2>
          <br />
          London Voice synthesises human voices and technology, using the latest emerging
          technologies and participatory research methodologies to build a data set of sense-making
          and dialogue that we think could inform civic decision makers of all kinds.
          <br />
          <br />
          <span className={styles.joinUs}>
            Join us in our experiment and help us build a better, more democratic society.
          </span>
        </div>
        <div className={styles.blackSection}>
          <span className={styles.yourVoiceTitle}>Your Voice, Your City, Your AI.</span>
          <br />
          <br />
          <span>
            We believe the future of AI is one in which data is ethically sourced and if it is
            monetised then people who provided it, should be rewarded for it.{' '}
            <strong>
              We aim to demonstrate the positive potential of technology when placed into the hands
              of the people.
            </strong>
          </span>
          <br />
          <br />
          London Voice will use the data that you provide to train a community managed AI (LLM) and
          ultimately be your digital representative. Our goal is to provide communities with the
          tools to control and evolve this representative over time.
        </div>
        <div className={styles.paragraph}>
          <span className={styles.yourData}>Your Data is Yours and it&apos;s Valuable.</span>
          <br />
          <br />
          <span>
            All of the data entered into the London VOICE web app should be considered “open source”
            with the understanding that no personal identifying information is kept by our system.
            We will only know you by your Voting ID.{' '}
          </span>
          <br />
          <br />
          <span>
            We may ask for your demographic data, which will improve the scientific outcomes of this
            experiment, but it is your choice whether you provide it.
          </span>
          <br />
          <br />
          <span>
            You can request to remove your data from the experiment. Your privacy, your data & your
            rights are of paramount importance to us.
          </span>
          <br />
          <br />
          <Link
            className={styles.privacy}
            href="https://ldnvoice.factorylabs.org/privacy-policy-app"
            target="_blank">
            PRIVACY POLICY
          </Link>
        </div>
        <div className={styles.learnMore}>
          <LearnMore />
        </div>
        <div className={styles.footer}>
          <span> “London Voice” is a democratic AI</span>
          <span> experiment facilitated by FactoryLabs 2024</span>
        </div>
      </div>
    </>
  );
};

export default Home;
